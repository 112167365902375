/* Base
========================================================================== */
// 1. Slider Classic
// 2. Slider Txt


jQuery(document).ready(function ($) {

  // 1. Slider Classic
  // --------------
  if (jQuery(".slider-classic").length > 0) {
    jQuery(".slider-classic").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      margin: 20,
      smartSpeed: 800,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    });
  }
  if (jQuery(".slider-classic-video").length > 0) {
    jQuery(".slider-classic-video").addClass("owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      margin: 20,
      smartSpeed: 800,
      onChange: function () {
        this.$element.find("iframe").each(function () {
          jQuery(this).stopVideo();
        })
      },
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    });
  }

  if (jQuery(".slider-classic-thumb").length > 0) {
    jQuery(".slider-classic-thumb").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      margin: 20,
      smartSpeed: 800,
      dotData: true,
      dotsData: true,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    });

    $('.slider-classic-thumb .owl-dot').on('click', function () {
      jQuery(this).parents(".owl-carousel").trigger('to.owl.carousel', [$(this).index(), 30,]);
      jQuery(this).parents(".owl-carousel .owl-dot").removeClass('active');
      $(this).addClass('active');
    })

  }

  // 2. Slider Txt
  // --------------
  if (jQuery(".slider-txt").length > 0) {
    jQuery(".slider-txt").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      margin: 20,
      smartSpeed: 800,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    })
  }

  // 3. Multiple Col + Slide
  // --------------
  if (jQuery(".section-multiplecol.slider.one_line .wrapper-items").length > 0) {
    jQuery(".section-multiplecol.slider.one_line .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      smartSpeed: 800,
      margin: 20,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
          items: 1,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          items: 3,
          margin: 40,
        },
        1200: {
          items: 3,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    })
  }

  // 4. List Static + Slide
  // --------------
  if (jQuery(".section-listestatique.slider:not('.text_top') .wrapper-items").length > 0) {
    jQuery(".section-listestatique.slider:not('.text_top') .wrapper-items").each(function(){
      nbItem = jQuery(this).parents(".section-listestatique").data("slider");
      console.log(nbItem)
      jQuery(this).addClass("owl-carousel").owlCarousel({
        loop: true,
        nav: true,
        dots: true,
        mouseDrag: true,
        touchDrag: true,
        smartSpeed: 800,
        responsive: {
          0: {
            autoplayHoverPause: false,
            autoplay: false,
            items: 1,
          },
          768: {
            margin: 20,
          },
          992: {
            autoplayHoverPause: true,
            autoplay: true,
            items: nbItem,
            margin: 40,
          },
          1200: {
            items: nbItem,
            margin: 40,
          },
          1600: {
            items: nbItem,
            margin: 40,
          }
        }
      })
    })

  }
  if (jQuery(".section-listestatique.slider.text_top .wrapper-items").length > 0) {
    jQuery(".section-listestatique.slider.text_top .wrapper-items").each(function () {
      nbItem = jQuery(this).parents(".section-listestatique.slider.text_top").data("slider");
      jQuery(this).addClass("owl-carousel").owlCarousel({
        loop: true,
        nav: true,
        dots: true,
        mouseDrag: true,
        touchDrag: true,
        smartSpeed: 800,
        responsive: {
          0: {
            autoplayHoverPause: false,
            autoplay: false,
            items: 1,
          },
          768: {
            margin: 20,
          },
          992: {
            autoplayHoverPause: true,
            autoplay: true,
            items: nbItem,
            margin: 40,
          },
          1200: {
            items: nbItem,
            margin: 40,
          },
          1600: {
            items: nbItem,
            margin: 40,
          }
        }
      })
    })
  }

  // 5. List Dynammic + Slide
  // --------------
  if (jQuery(".section-listedynamique.slider .wrapper-items").length > 0) {
    nbItem = 2;
    // if body has class current-e-journal-pse
    if (jQuery("body").hasClass("current-e-journal-pse")) {
      nbItem = 4;
    }
    jQuery(".section-listedynamique.slider .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      smartSpeed: 800,
      rtl: false,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
          items: 1,
        },
        768: {
          margin: 20,
          items: 2,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          items: nbItem,
          margin: 40,
        },
        1200: {
          items: nbItem,
          margin: 40,
        },
        1600: {
          items: nbItem,
          margin: 40,
        }
      }
    })
  }

  // 6. Slider Logos
  // --------------
  if (jQuery(".section-logos.slider .wrapper-items").length > 0) {
    jQuery(".section-logos.slider .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      smartSpeed: 800,
      margin: 30,
      autoplay: false,
      responsive: {
        0: {
          autoplayHoverPause: false,
          items: 1,
        },
        768: {
          margin: 30,
        },
        992: {
          autoplayHoverPause: true,
          //autoplay: true,
          items: 4,
          margin: 40,
        },
        1200: {
          autoplayHoverPause: true,
          //autoplay: true,
          items: 4,
          margin: 40,
        },
        1600: {
          margin: 40,
          items: 4,
        }
      }
    })
  }

  // 7. Slider Txt + Block
  // --------------
  if (jQuery(".section-sliderblock.slider .wrapper-items").length > 0) {
    jQuery(".section-sliderblock.slider .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      smartSpeed: 800,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 20,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 40,
        },
        1600: {
          margin: 40,
        }
      }
    })
  }

  // 8. Slider KPI
  // Section-flexible section-kpi text_right last_third slider
  // --------------
  if (jQuery(".section-kpi.slider.text_top").length > 0) {
    jQuery(".section-kpi.slider.text_top .inner-flex-block-kpi .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      smartSpeed: 800,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
          items: 1,
        },
        768: {
          margin: 30,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          items: 3,
          margin: 60,
        },
        1200: {
          items: 4,
        },
        1600: {
          margin: 120,
        }
      }
    })
  }
  if (jQuery(".section-kpi.slider.text_right, .section-kpi.slider.text_left").length > 0) {
    jQuery(".section-kpi.slider.text_right .inner-flex-block-kpi .wrapper-items, .section-kpi.slider.text_left .inner-flex-block-kpi .wrapper-items").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 1,
      smartSpeed: 800,
      responsive: {
        0: {
          autoplayHoverPause: false,
          autoplay: false,
        },
        768: {
          margin: 30,
        },
        992: {
          autoplayHoverPause: true,
          autoplay: true,
          margin: 60,
        },
        1600: {
          margin: 120,
        }
      }
    })
  }
});
