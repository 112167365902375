/* Client
========================================================================== */
jQuery(document).ready(function ($) {

  version = detectIE();
  if (version != false) {
    if (version <= 12) {
      jQuery.gbl_generate_modal(frontendajax.modalIE, true)
    }
  }

  if (jQuery(".animatedpicto").length > 0) {

    jQuery(".animatedpicto").each(function () {
      var params = {
        container: this,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/wp-content/themes/gblmaster/icons/' + jQuery(this).data("icon") + '.json'
      };

      var anim;
      anim = lottie.loadAnimation(params);
    })

  }




  //jQuery(".deletefilters").hide()
  jQuery(".deletefilters").click(function () {
    jQuery(this).hide()
    $("select").val("").change();
    return false;
  })

  //  rmv click has sub-menu
  if (window.matchMedia("(min-width: 1200px)").matches) {
    jQuery("li.menu-item-has-children>a").click(function () {
      return false;
    })
  }

  if (jQuery("body").hasClass("page-template-outils")) {
    jQuery(".filters .field-wrapper select#collections-outils").change(function () {
      $.ajax({
        url: frontendajax.ajaxurl,
        data: {
          'action': "gbl_get_tax_desc",
          'tax': jQuery(this).val(),
        },
        type: "POST",
        success: function (data) {
          jQuery(".collection-description").html(data);
        }
      });
    })

    jQuery(".filters .field-wrapper select").change(function () {
      var query = new Array()
      jQuery(".filters .field-wrapper select").each(function () {
        if (jQuery(this).val() != "") {
          var temp = jQuery(this).attr("name") + ":" + jQuery(this).val();
          query.push(temp);
        }
      })

      var $container = jQuery(".wrapper-grid.card-wrapper");
      var $loader = jQuery("body");
      $.ajax({
        url: frontendajax.ajaxurl,
        data: {
          'action': "updatetools",
          'tax': query,
        },
        type: "POST",
        beforeSend: function (xhr) {
          $loader.addClass("loading");
          $container.find("article").fadeOut(250, function () {
            jQuery(this).remove()
          });
          if (query.length > 0) {
            jQuery(".loadMorePost").hide();
          } else {
            jQuery(".loadMorePost").show().data("page", 2);
          }
        },
        success: function (data) {
          if (query.length > 0) {
            jQuery(".deletefilters").show()
          }
          $loader.removeClass("loading");
          setTimeout(function () {
            $container.html(data)
          }, 250);
        }
      });
      return false;
    })
  }
  if (jQuery("body").hasClass("page-template-all-articles-bxl")) {

    jQuery(".filters .field-wrapper select").change(function () {
      var query = new Array()
      jQuery(".filters .field-wrapper select").each(function () {
        if (jQuery(this).val() != "") {
          var temp = jQuery(this).attr("name") + ":" + jQuery(this).val();
          query.push(temp);
        }
      })

      var $container = jQuery(".wrapper-grid.card-wrapper");
      var $loader = jQuery("body");
      $.ajax({
        url: frontendajax.ajaxurl,
        data: {
          'action': "updatebxlarticles",
          'tax': query,
        },
        type: "POST",
        beforeSend: function (xhr) {
          $loader.addClass("loading");
          $container.find("article").fadeOut(250, function () {
            jQuery(this).remove()
          });
          console.log(query.length)
          if (query.length > 0) {
            jQuery(".loadMorePost").hide();
          } else {
            jQuery(".loadMorePost").show().data("page", 2);
          }
        },
        success: function (data) {
          if (query.length > 0) {
            jQuery(".deletefilters").show()
          }
          $loader.removeClass("loading");
          setTimeout(function () {
            $container.html(data)
          }, 250);
        }
      });
      return false;
    })
  }
  if (jQuery("body").hasClass("page-template-all-articles-pse")) {

    jQuery(".filters .field-wrapper select").change(function () {
      var query = new Array()
      jQuery(".filters .field-wrapper select").each(function () {
        if (jQuery(this).val() != "") {
          var temp = jQuery(this).attr("name") + ":" + jQuery(this).val();
          query.push(temp);
        }
      })

      var $container = jQuery(".wrapper-grid.card-wrapper");
      var $loader = jQuery("body");
      $.ajax({
        url: frontendajax.ajaxurl,
        data: {
          'action': "updatepsearticles",
          'tax': query,
        },
        type: "POST",
        beforeSend: function (xhr) {
          $loader.addClass("loading");
          $container.find("article").fadeOut(250, function () {
            jQuery(this).remove()
          });
          console.log(query.length)
          if (query.length > 0) {
            jQuery(".loadMorePost").hide();
          } else {
            jQuery(".loadMorePost").show().data("page", 2);
          }
        },
        success: function (data) {
          if (query.length > 0) {
            jQuery(".deletefilters").show()
          }
          $loader.removeClass("loading");
          setTimeout(function () {
            $container.html(data)
          }, 250);
        }
      });
      return false;
    })
  }
  if (jQuery("body").hasClass("home")) {

    jQuery(".home .last-news>.wrapper").addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 2,
      margin: 30,
      smartSpeed: 800,
      responsive: {
        0: {
          items: 1,
          margin: 30,
        },
        992: {
          items: 2,
          margin: 40,
        },
        1200: {
          margin: 60,
        },
        1600: {
          margin: 120,
        }
      }
    });
    /*  jQuery(".home .soutiens .logos").addClass("owl-carousel").owlCarousel({
       loop: true,
       nav: true,
       dots: true,
       mouseDrag: true,
       touchDrag: true,
       items: 5,
       margin: 30,
       smartSpeed: 800,
     }); */
    jQuery(".home .projets  .slider-wrapper").addClass("owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      mouseDrag: true,
      touchDrag: true,
      margin: 30,
      smartSpeed: 800,
      responsive: {
        1200: {
          items: 2,
        },
        1366: {
          items: 3,
        }
      }
    });

    var $parent = $(".productions .slidewrapper");
    var divs = $parent.children();
    while (divs.length) {
      $parent.append(divs.splice(Math.floor(Math.random() * divs.length), 1)[0]);
    }
    $parent.addClass("owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      mouseDrag: true,
      touchDrag: true,
      items: 5,
      smartSpeed: 800,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 2,
          margin: 30,
        },
        1200: {
          items: 5,
          margin: 2,
        },
      }
    });


    jQuery("a.hp-tab-link").click(function () {
      if (window.matchMedia("(max-width: 1200px)").matches) {
        return true;
      }
      $clicker = jQuery(this);
      $section = $clicker.parents("section");
      $section.find('.hp-tab-link').removeClass("active");
      $clicker.addClass("active");
      $section.find(".slider-item").removeClass("active").filter("[data-tab='" + $clicker.data("dest") + "']").addClass("active");
      return false
    })

  }

  if (jQuery("#Nouvelle_nuance_de_motif_1").length > 0) {
    jQuery("#infos>g").hover(
      function () {
        //jQuery("#infos>g").removeClass("active").addClass("inactive")
        jQuery("#infos").parents("svg").attr("data-active", $(this).data("cat"))
        jQuery(this).addClass("active");
      }, function () {
        jQuery("#infos").parents("svg").attr("data-active", "")
        jQuery("#infos>g").removeClass("active")
      }
    );
    jQuery("#infos>g").click(function () {
      var url = jQuery(this).data("url");
      if (url != "") {
        window.location.href = url;
      }
    });
  }
})


function detectIE() {
  var ua = window.navigator.userAgent;
  // Test values; Uncomment to check result
  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  // other browser
  return false;
}
