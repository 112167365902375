/* Common
========================================================================== */

jQuery(document).ready(function ($) {


	jQuery(".clic-email-convert").click(function () {
		$mail = atob(jQuery(this).data("email"));
		if (jQuery(this).attr("href") == "") {
			jQuery(this).attr("href", "mailto:" + $mail);
			jQuery(this).html($mail);
			return false;
		}
	})
	lightbox.option({
		'resizeDuration': 200,
		'wrapAround': true,
		'disableScrolling': true,
		'showImageNumberLabel': false,
	})

	jQuery(".read-more-excerpt").click(function(){
		jQuery(this).hide().parents("article").find(".text-end").show()
		jQuery(this).parents("article").find(".etc").hide()
	})

	jQuery("a.btn-lightbox").attr("data-lightbox", "ddd");

	// Hover submenu
	//----------------
	//	if (jQuery(window).width() >= 1200) {
	//		jQuery("#primary-menu .menu-item-has-children").hover(function () {
	//			$(this).find("ul").toggleClass("open");
	//		})
	//	}


	// Click submenu on mobile
	//----------------
	//	if (jQuery(window).width() <= 1199) {
	//		jQuery("#primary-menu .menu-item-has-children").click(function () {
	//			$(this).find("ul").toggleClass("open");
	//		})
	//	}


	// Anchor smoth scrolling
	//----------------
/* 	jQuery(".intraScroll").click(function () {
		var url = $(this).attr("href");
		var h = url.split("/");
		h = h[h.length - 1]
		var divPosition = jQuery(h).offset().top;
		var menuHeight = jQuery("#site-header").height()
		jQuery("html, body").stop().animate({
			scrollTop: divPosition - menuHeight - 20
		}, 800);
		return false;
	}); */

  jQuery(".intraScroll a").click(function () {
		var url = $(this).attr("href");
		var h = url.split("/");
		h = h[h.length - 1]
    if (jQuery(h).length > 0) {
      var divPosition = jQuery(h).offset().top - 50;
      var menuHeight = jQuery("#site-header").height()
      jQuery("html, body").stop().animate({
        scrollTop: divPosition - menuHeight - 20
      }, 800);
      return false;
    }
	});

  if (window.location.hash) {
    marge = jQuery("#site-header").height();
    jQuery('html, body').animate({ scrollTop: jQuery(window.location.hash).offset().top - marge }, 250);
    console.log(jQuery(window.location.hash).offset().top , marge)
  }

})
