/* Modal
========================================================================== */
jQuery(document).ready(function ($) {

  jQuery(document).on('click', '.modal-opener', function (event) {
    event.preventDefault();
    $modal = jQuery("#" + jQuery(this).data("modal"));
    $modal.addClass('open');
    $modal.find(".videoPlay").trigger("click")
    jQuery("body").addClass('modal-open');
  });

  jQuery(document).on('click', '.modal .close', function (event) {
    close_modal()
  });

  jQuery('.modal').on('click', function (e) {
    if (e.target !== this) return;
    close_modal()
  });

  jQuery("body").keydown(function (e) {
    if (e.keyCode == 27) {
      close_modal()
    }
  });

  function close_modal() {
    $modal = jQuery(".modal.open");
    $modal.removeClass('open');
    jQuery("body").removeClass('modal-open');
    if ($modal.hasClass("modal-video")) {
      if (typeof $modal.find("iframe").data("init") !== "undefined") {
        $modal.find("iframe").attr("src", $modal.find("iframe").data("init"))
      }else{
        $modal.find("iframe").attr("src", $modal.find("iframe").attr("src"));
      }
    }
  }

  if(jQuery(".modal.openinit").length){
    jQuery(".modal.openinit").open()
  }
})

jQuery(function () {
  jQuery.gbl_generate_modal = function(modalContent, open, modal_class) {
    if(!modal_class){
      modal_class ="";
    }
    jQuery.ajax({
      url: frontendajax.ajaxurl,
      type: "POST",
      data: {
        'action': 'get_modal_ajax',
        'content': modalContent,
        'modal_class': modal_class
      }
    }).done(function (response) {
      jQuery('body').append(response.modal); // Afficher le HTML
      if (open) {
        jQuery("#" + response.id).open();
      }
      return response.id;
    });
  }
})

jQuery.fn.open = function () {
  var $el = jQuery(this[0]) // This is the element
  if ($el.hasClass("modal")) {
    $el.addClass("open");
    jQuery("body").addClass('modal-open');
  }
  return this; // This is needed so other functions can keep chaining off of this
};
jQuery.fn.close = function () {
  jQuery(".modal").removeClass('open');
  jQuery("body").removeClass('modal-open');
  return this; // This is needed so other functions can keep chaining off of this
};
