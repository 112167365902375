/* Video
========================================================================== */

videoIds = new Array();

function onYouTubeIframeAPIReady() {

  for (property in yt_videos) {
    var AutoPlay = 0;
    if (jQuery('#video_' + property).hasClass('autoPlaycustom')) {
      AutoPlay = 1;
    }

    videoIds[property] = new YT.Player('video_' + property, {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: AutoPlay,
        loop: 0,
        controls: 1,
        iv_load_policy: 3,
        rel: 0,
        showinfo: 0,
        autohide: 1,
        modestbranding: 1
        //vq: 'hd1080'
      },
      videoId: yt_videos[property],
    })

  }
}



jQuery(document).on('click', '.videoPlay', function (event) {
  event.preventDefault();
  clicker = jQuery(this);
  if (typeof clicker.data("video") != 'undefined') {
    videoIds[clicker.data("video")].playVideo();
    clicker.parents("article").addClass("playing")
  } else {
    $modal = clicker.parents(".modal")
    if ($modal.hasClass("modal-video")) {
      $modal.find("iframe").data("init", $modal.find("iframe")[0].src);
      $modal.find("iframe")[0].src += "?autoplay=1";
    }
  }
  setTimeout(function () { clicker.hide() }, 100)
});

if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
  document.body.className += ' ' + 'apple';
}


jQuery.fn.stopVideo = function () {
  var $el = jQuery(this[0]) // This is the element
  var id = $el.attr("id");
  splittedId = id.split('_');
  ytId = splittedId[0];
  if (typeof clicker != 'undefined' && typeof videoIds[clicker.data("video")] != 'undefined') {
    videoIds[clicker.data("video")].stopVideo();
    $el.parents("article").removeClass("playing")

  }
  return this; // This is needed so other functions can keep chaining off of this
};
