/* Modal
========================================================================== */
jQuery(document).ready(function ($) {

  if (jQuery(".infinite").length > 0) {
    window.addEventListener('scroll', function (e) {
      if (!jQuery("body").hasClass("loading") && jQuery(document).scrollTop() + document.documentElement.clientHeight >= jQuery(".infinite").offset().top) {
        jQuery(".loadMorePost").trigger("click");
      }
    });
  }

  $(document).on('click', '.loadMorePost', function () {
    var $clicker = jQuery(this);
    var $loader = $clicker.parents(".loadMoreWrapper");
    var page = $clicker.data("page");
    if (page <= $clicker.data("max")) {
      $.ajax({
        url: frontendajax.ajaxurl,
        data: {
          'action': 'gblloadmore',
          'post_type': $clicker.data("type"),
          'pa': $clicker.data("page"),
          'nb': $clicker.data("nb"),
          'taxo': $clicker.data("tax")
        },
        type: 'POST',
        beforeSend: function (xhr) {
          $loader.addClass("loading");

        },
        success: function (data) {
          $loader.removeClass("loading");

          if ($clicker.parents("section").hasClass('section-flexible')) {
            $clicker.parents("section").find('.' + $clicker.data("wrapper")).append(data)
          } else {
            $('.' + $clicker.data("wrapper")).append(data)
          }
          page += 1;
          $clicker.data("page", page);
          if (page > $clicker.data("max")) {
            $clicker.hide();
          }
          if (window.DEARPDF && window.DEARPDF.parseElements) {
            window.DEARPDF.parseElements();
          }
        }
      });
    }
    return false;
  });
});
