/* Toggle
========================================================================== */

jQuery(document).ready(function($) {
  //toggle
  if (jQuery(".toggle-wrapper").length > 0 ) {

  	jQuery(".toggle-wrapper .toggle-item label").click(function(event) {
  		$parent = jQuery(this).parents(".toggle-wrapper")
  		$parent.find("input[name=faq]").not(jQuery(this).prev()).prop("checked", false);
  	});
  }
});
